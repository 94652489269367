import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';

const PieChartsBox = ({empStrCount,catagory}) => {

    const [chartState, setChartState] = useState({
        // series: [44, 55, 41, 17, 15],
        series: [],
        options: {
            chart: {
                type: 'donut',
            },
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200,
                    },
                    legend: {
                        position: 'bottom',
                    },
                },
            }],
        },
    });

 

    // Update chart data when employee count changes
    useEffect(() => {
        setChartState(prevState => ({
            ...prevState,
            series:empStrCount,
            options: {
                ...prevState.options,
                // colors: colors.slice(0, data.length),
                labels: catagory
              },
        }));
    }, [empStrCount]);


     // Update chart data when employee count changes
    //  useEffect(() => {
    //     let seriesData = catagory?.map((name, index) => ({
    //         seriesName: name,
    //         data: empStrCount[index],
    //     }));
    //     console.log("catagory",seriesData)
    //     setChartState(prevState => ({
    //         ...prevState,
    //         series: seriesData,
    //     }));
    // }, [empStrCount, catagory]);

    

    // console.log("catagory",catagory)
    return (
        <>
            <section className='piechartsBox_area'>
                <h5>Count Of companies with Strenght</h5>
                <div id="chart">
                    <ReactApexChart options={chartState.options} series={chartState.series} type="donut" />
                </div>
            </section>
        </>
    )
}

export default PieChartsBox