import React, { useEffect, useState } from 'react'
import CustomLoader from '../../CustomComponents/loader/CustomLoader'
import DataTable from 'react-data-table-component';
import toast from 'react-hot-toast';
import HttpClientXml from '../../utils/HttpClientXml';


const headLineStyle = {
    textAlign: "center",
    fontSize: "30px",
    color: "#868e96",
    margin: "11px",
    fontWeight: "bold"

}
const customStyles = {
    rows: {
        style: {
            minHeight: '48px', // override the row height
        },
    },
    headCells: {
        style: {
            paddingLeft: '8px', // override the cell padding for head cells
            paddingRight: '8px',
            backgroundColor: '#cee0eb', // set the background color for head cells
        },
    },
    cells: {
        style: {
            paddingLeft: '8px', // override the cell padding for data cells
            paddingRight: '8px',
        },
    },
};

const RegisterCompanyView = () => {
    const [AllData, setAllData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);


    // column
    const columns = [
        {
            name: 'SL',
            selector: row => row?.sl,
        },
        {
            name: 'Company Name',
            selector: row => row?.companyName,
        },

        {
            name: 'Registration Number',
            selector: row => row?.registrationNumber,
        },

        {
            name: 'email',
            selector: row => row?.email,
        },

        {
            name: 'address',
            selector: row => row?.address,
        },
        {
            name: 'Registered PhoneNo',
            selector: row => row?.registeredPhoneNo,
        },
        {
            name: 'Employee Strenght',
            selector: row => row?.employeeStrenght,
        },
        {
            name: 'Status',
            selector: row => row?.status,
        },

    ];


    //for approve register company
    const handleApprove = async (id) => {
        setIsLoading(true);
        let data = {
            isApproved: "accept"
        }
        const res = await HttpClientXml.requestData(`approve-reject/${id}`, data, "POST");
        if (res && res?.status) {
            setIsLoading(false);
            toast.success(res?.message);
            getRegisterCompanyData();
        } else {
            setIsLoading(false);
        }

    }


    //for reject register company
    const handleReject = async (id) => {
        setIsLoading(true);
        let data = {
            isApproved: "reject"
        }

        const res = await HttpClientXml.requestData(`approve-reject/${id}`, data, "POST");
        if (res && res?.status) {
            setIsLoading(false);
            toast.success(res?.message);
            getRegisterCompanyData();
        } else {
            setIsLoading(false);
        }
    }

    //for fetching all data of register company
    const getRegisterCompanyData = async () => {
        setIsLoading(true);
        const res = await HttpClientXml?.requestData("view-company-req", {}, "GET");
        let apiData = []
        if (res && res?.status) {
            setIsLoading(false);
            apiData = res?.data?.map((item, i) => ({
                sl: i + 1,
                companyName: item?.companyName,
                email: item?.email,
                address: item?.address,
                employeeStrenght: item?.employeeStrenght,
                registeredPhoneNo: item?.registeredPhoneNo,
                registrationNumber: item?.registrationNumber,
                status: <div>
                    {
                        item?.isApproved === "pending" ?

                            (
                                <div className='btnaprdrjct'>
                                    <button onClick={() => handleApprove(item?._id)}
                                        type="button" className="btn btn-success floating-message aprvbtn"
                                        data-toggle="tooltip" data-placement="top" title="Click to change">
                                        Approve
                                    </button>
                                    <button type="button" className="btn btn-danger floating-message aprvbtn"
                                        onClick={() => handleReject(item?._id)}
                                        data-toggle="tooltip" data-placement="top" title="Click to change">
                                        Reject
                                    </button>
                                </div>
                            )
                            :
                            item?.isApproved === "accept" ?
                                (
                                    <button className="btn btn-success floating-message">Approved</button>
                                )
                                :
                                <button className="btn btn-danger floating-message">Rejected</button>
                    }

                </div >,

            }));
        } else {
            setIsLoading(false);
        }
        setAllData(apiData);


    }

    useEffect(() => {
        getRegisterCompanyData();
    }, [])


    return (
        <div className="d-flex justify-content-end">
            <div className="form-header">

                <CustomLoader loading={isLoading} />
                <section className="piechartsBox_area">
                    <div
                        style={headLineStyle}
                        className="page-headline"
                    >
                        All Requested Companies
                    </div>

                    {/* <form> */}

                    <div>

                        <DataTable
                            columns={columns}
                            data={AllData}
                            pagination
                            striped
                            className=" rounded "
                            customStyles={customStyles}
                        />
                    </div>
                    {/* </form> */}
                </section>
            </div>
        </div>

    )
}

export default RegisterCompanyView