import React from "react";
// import { toast } from "react-hot-toast";
import { Link, NavLink, useNavigate } from "react-router-dom";
import logo from "../../src/Images/logo.png";
import { toast } from "react-hot-toast";

function Sidebar() {

  const navigate = useNavigate();

  const handlClick = () => {
    if (document.querySelector("#backbtnsidebar").click) {
      document.querySelector(".sidebarwrap").classList.remove("show");
    } else {
      document.querySelector(".sidebarwrap").classList.remove("show");
    }
  };



  const handleLogOut = () => {
    localStorage.clear();
    navigate("/login");
  };
  return (
    <>
      <section className="sidebarwrap">
        <div className="top_area">
          <button id="backbtnsidebar" >
            <i className="fa-solid fa-arrow-left"></i>
          </button>

          <div className="logo_area">
            <div className="logo">
              {/* <img src={logo} className="img-fluid" alt="logo" /> */}
              <h1 style={{ color: "#fff" }}>Logo</h1>
            </div>
          </div>
        </div>

        <div className="sidebar_menu">
          <ul className="nav Menu_Nav accordion" id="sidemenu">

            {/*** Dashboard ***/}
            <li className="menuline">
              <NavLink to="/" className="" >
                <i className="fa-solid fa-bars"></i>
                <span>Dashboard</span>
              </NavLink>
            </li>

            {/*** Login ***/}
            {/* <li className="menuline">
              <div className="menu-head" id="sidemenuhead1">
                <Link to="#"
                  className="btn btn-header-link"
                  data-toggle="collapse"
                  data-target="#sidemenu1"
                  aria-expanded="true"
                  aria-controls="sidemenu1"
                >
                  <i className="fa-regular fa-address-card"></i>
                  <span>Login</span>
                </Link>
              </div>
              <div
                id="sidemenu1"
                className="collapse"
                aria-labelledby="sidemenuhead1"
                data-parent="#sidemenu"
              >
                <ul className="Submenu">
                  <li><Link to="/login"><i className="fa-solid fa-angles-right mr-2"></i>Get Login</Link></li>
                 </ul>
              </div>
            </li> */}

            {/*** Register Company ***/}
            <li className="menuline">
              <div className="menu-head" id="sidemenuhead12">
                <Link
                  to="#"
                  className="btn btn-header-link icon"
                  data-toggle="collapse"
                  data-target="#sidemenu12"
                  aria-expanded="true"
                  aria-controls="sidemenu12"
                >
                  <i className="fa-sharp fa-solid fa-signs-post icon-1"></i>
                  <span>Register Company</span>
                </Link>
              </div>
              <div
                id="sidemenu12"
                className="collapse"
                aria-labelledby="sidemenuhead12"
                data-parent="#sidemenu"
              >
                <ul className="Submenu">
                  <li>
                    <Link to="/register-company" onClick={handlClick}>
                      <i className="fa-solid fa-angles-right mr-2"></i>
                      Company Request
                    </Link>
                  </li>

                  <li>
                    <Link to="/approved-company" onClick={handlClick}>
                      <i className="fa-solid fa-angles-right mr-2"></i>
                      Registared companies
                    </Link>
                  </li>
                </ul>
              </div>
            </li>


            {/* Industry Type */}
            <li className="menuline">
              <NavLink to="/industry" className="menu-head">
                <i className="fa-solid fa-bars"></i>
                <span>Industry type</span>
              </NavLink>
            </li>


            <li className="menuline">
              <div className="menu-head" id="sidemenuhead12">
                <Link
                  to="#"
                  className="btn btn-header-link icon"
                  data-toggle="collapse"
                  data-target="#sidemenu13"
                  aria-expanded="true"
                  aria-controls="sidemenu13"
                >
                  <i className="fa-sharp fa-solid fa-signs-post icon-1"></i>
                  <span>Survey</span>
                </Link>
              </div>
              <div
                id="sidemenu13"
                className="collapse"
                aria-labelledby="sidemenuhead13"
                data-parent="#sidemenu"
              >
                <ul className="Submenu">
                  <li>
                    <Link to="/login-survey-question" onClick={handlClick}>
                      <i className="fa-solid fa-angles-right mr-2"></i>
                       Add & Manage Survey
                    </Link>
                  </li>

                  <li>
                    <Link to="/analys-data" onClick={handlClick}>
                      <i className="fa-solid fa-angles-right mr-2"></i>
                      View & Analysis Data
                    </Link>
                  </li>
                </ul>
              </div>
            </li>

            {/***Menu 5***/}
            {/* <li className="menuline">
              <div className="menu-head" id="sidemenuhead7">
                <Link to="#"
                  className="btn btn-header-link"
                  data-toggle="collapse"
                  data-target="#sidemenu7"
                  aria-expanded="true"
                  aria-controls="sidemenu7"
                >
                  <i className="fa-regular fa-address-card"></i>
                  <span>User Contact</span>
                </Link>
              </div>
              <div
                id="sidemenu7"
                className="collapse"
                aria-labelledby="sidemenuhead7"
                data-parent="#sidemenu"
              >
                <ul className="Submenu">
                  <li><Link to="/view-user-contact" onClick={handlClick}><i className="fa-solid fa-angles-right mr-2"></i>View User Contact</Link></li>
                  {/* <li><Link to="/view-user-email" onClick={handlClick}><i className="fa-solid fa-angles-right mr-2"></i>View User Email</Link></li> */}
            {/* <li><NavLink to="page2" onClick={handlClick}><i className="fa-solid fa-angles-right mr-2"></i>Sub Menu 2</NavLink></li>
                  <li><NavLink to="page3" onClick={handlClick}><i className="fa-solid fa-angles-right mr-2"></i>Sub Menu 3</NavLink></li> */}
            {/* </ul>
              </div>
            </li>  */}
          </ul>



          <ul className="nav Account_Nav">
            <div style={{ width: "100%" }} onClick={handleLogOut}>
              <Link>
                <i className="fa-solid fa-right-from-bracket mr-2"></i>
                <span>Logout</span>
              </Link>
            </div>
          </ul>
        </div>
      </section>
    </>
  )
}

export default Sidebar