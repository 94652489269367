import React, { useEffect, useState } from 'react'
import CustomLoader from '../../CustomComponents/loader/CustomLoader'
import DataTable from 'react-data-table-component';
import toast from 'react-hot-toast';
import HttpClientXml from '../../utils/HttpClientXml';
import { useLocation, useParams } from 'react-router-dom';


const headLineStyle = {
    textAlign: "center",
    fontSize: "30px",
    color: "#868e96",
    margin: "11px",
    fontWeight: "bold"

}
const customStyles = {
    rows: {
        style: {
            minHeight: '48px', // override the row height
        },
    },
    headCells: {
        style: {
            paddingLeft: '8px', // override the cell padding for head cells
            paddingRight: '8px',
            backgroundColor: '#cee0eb', // set the background color for head cells
        },
    },
    cells: {
        style: {
            paddingLeft: '8px', // override the cell padding for data cells
            paddingRight: '8px',
        },
    },
};

const EmpStrCompany = () => {
    const location = useLocation();
    const params = useParams();
    // console.log("FilteredData",location?.state, params)
    const [FilteredData, setFilteredData] = useState([]);

    const [AllData, setAllData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    // const [companyName, setcompanyName] = useState("");


    useEffect(() => {
        const filteredData = location?.state?.find(item => item?._id === params?.ans)?.data || [];
        setFilteredData(filteredData);
        // getApprovedCompanyData();
    }, [location?.state, params?.ans])

    // column
    const columns = [
        {
            name: 'SL',
            selector: row => row?.sl,
        },
        {
            name: 'Company Name',
            selector: row => row?.companyName,
        },

        {
            name: 'Registration Number',
            selector: row => row?.registrationNumber,
        },

        {
            name: 'email',
            selector: row => row?.email,
        },

        {
            name: 'address',
            selector: row => row?.address,
        },
        {
            name: 'Registered PhoneNo',
            selector: row => row?.registeredPhoneNo,
        },
        {
            name: 'Employee Strenght',
            selector: row => row?.employeeStrenght,
        },
        {
            name: 'Status',
            selector: row => row?.status,
        },

    ];


    //for approve register company
    // const handleDeactive = async (id) => {
    //     setIsLoading(true);
    //     let data = {
    //         isActive: false
    //     }
    //     const res = await HttpClientXml.requestData(`status-active-inactive/${id}`, data, "POST");
    //     if (res && res?.status) {
    //         setIsLoading(false);
    //         toast?.success("Your status is inactive now");
    //         getApprovedCompanyData();
    //     } else {
    //         setIsLoading(false);
    //     }

    // }


    //for reject register company
    // const handleActive = async (id) => {
    //     setIsLoading(true);
    //     let data = {
    //         isActive: true
    //     }
    //     const res = await HttpClientXml.requestData(`status-active-inactive/${id}`, data, "POST");
    //     if (res && res?.status) {
    //         setIsLoading(false);
    //         toast?.success("Your status is active now");
    //         getApprovedCompanyData();
    //     } else {
    //         setIsLoading(false);
    //     }
    // }
    


    //for fetching all data of register company
    const getApprovedCompanyData = () => {
        let apiData = []
        apiData = FilteredData?.map((item, i) => ({
            sl: i + 1,
            companyName: item?.companyName,
            email: item?.email,
            isApproved: item?.isApproved,
            isActive:item?.isActive,
            address: item?.address,
            employeeStrenght: item?.employeeStrenght,
            registeredPhoneNo: item?.registeredPhoneNo,
            registrationNumber: item?.registrationNumber,
            status: <div>
                {
                    item?.isActive ?
                        <button 
                        // onClick={() => handleDeactive(item?._id)}
                            type="button" className="btn btn-success floating-message"
                            data-toggle="tooltip" data-placement="top" title="Click to change">
                            Active
                        </button> :
                        <button type="button" className="btn btn-danger floating-message"
                            // onClick={() => handleActive(item?._id)}
                            data-toggle="tooltip" data-placement="top" title="Click to change">
                            Inactive
                        </button>
                }

            </div >,

        }));
        setAllData(apiData);
    }

    useEffect(() => {
        getApprovedCompanyData();
    }, [FilteredData])


    return (
        <div className="d-flex justify-content-end">
            <div className="form-header">

                <CustomLoader loading={isLoading} />
                <section className="piechartsBox_area">
                    <div
                        style={headLineStyle}
                        className="page-headline"
                    >
                        View Company
                    </div>

                    <form>

                        <div>

                            <DataTable
                                columns={columns}
                                data={AllData}
                                pagination
                                striped
                                className=" rounded "
                                customStyles={customStyles}
                            />
                        </div>
                    </form>
                </section>
            </div>
        </div>

    )
}

export default EmpStrCompany