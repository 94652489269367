import React, { useEffect, useState } from 'react'
// import PieChartsBox from '../../View/Home/PieChartsBox'
import CustomLoader from '../../CustomComponents/loader/CustomLoader'
import toast from 'react-hot-toast';
import HttpClientXml from '../../utils/HttpClientXml';
import UserEmailModal from './UserEmailModal';
import PieChartBox from '../Home/PieChartBox';


const Analys = () => {
    const [isLoading, setIsLoading] = useState(false);
    // const [catName, setCatName] = useState()
    const [catData, setCatData] = useState([]);
    const [AnsData, setAnsData] = useState([]);
    // const [AllData, setAllData] = useState([]);
    // const [imageLoader, setImgLoader] = useState(false);
    const [catID, setCatID] = useState("");
    // const [isDelete, setIsDelete] = useState(true);
    const [emailmodal, setemailmodal] = useState(false);
    const [AnsPercentage1, setAnsPercentage1] = useState("");
    const [AnsPercentage2, setAnsPercentage2] = useState("");
    const [AnsPercentage3, setAnsPercentage3] = useState("");
    const [AnsPercentage4, setAnsPercentage4] = useState("");
    // const [AnsOption, setAnsOption] = useState([]);
    const [selectedAnswerData, setSelectedAnswerData] = useState([]);
    const [filterAnswerData, setfilterAnswerData] = useState([]);

    const AnsPercentage = [AnsPercentage1, AnsPercentage2, AnsPercentage3, AnsPercentage4];
    const AnsOption = ["A", "B", "C", "D"]
    // console.log(catID, "catID")
    console.log(AnsPercentage1, AnsOption, "AnsData")


    // get Question data
    const getQuestionData = async () => {
        setIsLoading(true);
        const res = await HttpClientXml?.requestData("get-login-survey", {}, "GET");
        console.log("resCat", res);
        if (res && res?.status) {
            setIsLoading(false);
            setCatData(res?.data?.[0]?.data);
        } else {
            setIsLoading(false);
            toast(res?.message)
        }

    }

    // other inputs change
    const handleChange = (e) => {
        setCatID(e.target.value)
    }

    // get answer wise data
    const getAnsWiseData = async (id) => {
        if (!id) return;
        const res = await HttpClientXml?.requestData("get-login-survey-answise/" + id, {}, "POST")
        if (res && res?.status) {
            setAnsData(res?.data?.[0])
            // setAnsOptions(Object.keys(res?.data?.[0]));
            setAnsPercentage1(res?.data?.[0]?.surveyAttempt?.[0]?.results?.find(item => item.answer === "A")?.count)
            setAnsPercentage2(res?.data?.[0]?.surveyAttempt?.[0]?.results?.find(item => item.answer === "B")?.count)
            setAnsPercentage3(res?.data?.[0]?.surveyAttempt?.[0]?.results?.find(item => item.answer === "C")?.count)
            setAnsPercentage4(res?.data?.[0]?.surveyAttempt?.[0]?.results?.find(item => item.answer === "D")?.count)
            setSelectedAnswerData(res?.data?.[0]?.surveyAttempt?.[0]?.results)
        }

    }

    // const handlemodalClick=(answer)=>{
    //     setemailmodal(!emailmodal)
    // }

    const handlemodalClick = (answer, event) => {
        event.preventDefault();
        const filteredData = selectedAnswerData?.find(item => item?.answer === answer)?.data || [];
        console.log("selectedAnswerData", selectedAnswerData, filteredData)
        setfilterAnswerData(filteredData);
        setemailmodal(true);
    };

    // const isValidPercentage = (percentage) => {
    //     return percentage !== "undefined" && percentage !== "";
    // };


    useEffect(() => {
        getQuestionData();
    }, [])


    useEffect(() => {
        if (catID) {
            getAnsWiseData(catID);
        }
    }, [catID])

    return (
        <div className="d-flex justify-content-end">
            <div className="form-header">

                <CustomLoader loading={isLoading} />
                <div className="three mb-1">
                    <h5>Answer Survey</h5>
                </div>
                <section className="piechartsBox_area">

                    <form>
                        <div className="row">
                            <div className="col-12 mb-4">
                                <label htmlFor="formGroupExampleInput">All Questions</label>
                                <select
                                    class="form-control"
                                    aria-label="Default select example"
                                    name="catID"
                                    value={catID}
                                    onChange={handleChange}
                                >
                                    <option value={""} disabled>Select Question</option>
                                    {catData?.map((item, i) =>
                                        <option key={i} value={item?._id}>{item?.surveyQuestion}</option>
                                    )
                                    }
                                </select>
                            </div>
                            {catID && <div className="col-6  mt-2 mx-3" >
                                <table className="table border border-black "
                                >
                                    <thead>
                                        <tr>

                                            <th>Option</th>
                                            <th>Answers</th>
                                            <th>Count</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {AnsData?.A && <tr className="table-primary">

                                            <td>A</td>
                                            <td>{AnsData?.A}</td>
                                            <td>{AnsPercentage1}</td>
                                            <td><button className='btn btn-success' onClick={(event) => { handlemodalClick("A", event) }}>View</button></td>
                                        </tr>}
                                        {AnsData?.B && <tr className="table-secondary">

                                            <td>B</td>
                                            <td>{AnsData?.B}</td>
                                            <td>{AnsPercentage2}</td>
                                            <td><button className='btn btn-success' onClick={(event) => { handlemodalClick("B", event) }}>View</button></td>
                                        </tr>}
                                        {AnsData?.C && <tr className="table-success">

                                            <td>C</td>
                                            <td>{AnsData?.C}</td>
                                            <td>{AnsPercentage3}</td>
                                            <td><button className='btn btn-success' onClick={(event) => { handlemodalClick("C", event) }}>View</button></td>
                                        </tr>}
                                        {AnsData?.D && <tr className="table-info">

                                            <td>D</td>
                                            <td>{AnsData?.D}</td>
                                            <td>{AnsPercentage4}</td>
                                            <td><button className='btn btn-success' onClick={(event) => { handlemodalClick("D", event) }}>View</button></td>
                                        </tr>
                                        }

                                    </tbody>
                                </table>
                            </div>
                            }

                            {/* {
                                catID &&
                                    AnsPercentage1 !== "undefined" || AnsPercentage1 !== ""
                                    && AnsPercentage2 !== "undefined" || AnsPercentage2 !== ""
                                    && AnsPercentage3 !== "undefined" || AnsPercentage3 !== ""
                                    && AnsPercentage4 !== "undefined" || AnsPercentage4 !== ""
                                    ?
                                    <div className="col-5">
                                        <section className='piechartsBo45x_area'>
                                            <PieChartBox AnsPercentage={AnsPercentage} AnsOption={AnsOption} />
                                        </section>
                                    </div>
                                    : ""
                            } */}
                            {
                                catID && AnsPercentage&&
                                // AnsPercentage?.every(isValidPercentage) &&
                                <div className="col-5">
                                    <section className='piechartsBox_area'>
                                        <PieChartBox AnsPercentage={AnsPercentage} AnsOption={AnsOption} />
                                    </section>
                                </div>
                            }
                        </div>


                        <br />

                    </form>


                </section>
                {
                    emailmodal && <UserEmailModal closemodal={setemailmodal} answerData={filterAnswerData} />
                }

            </div>

        </div>
    )
}

export default Analys