// Hooks
import React, { useState } from "react";
import "./Authentication.css";
import loginbg from "../Images/LoginBG.jpeg";
import toast from "react-hot-toast";
import { reactLocalStorage } from "reactjs-localstorage";
import { useNavigate } from "react-router-dom";
import { FaEyeSlash } from 'react-icons/fa';
import { FaEye } from 'react-icons/fa';
import HttpClientXml from "../utils/HttpClientXml";

export default function ChangePassword() {
    const navigate = useNavigate();
    //   const [email, setEmail] = useState("");
    const [oldPass, setoldPass] = useState("");
    const [newPass, setnewPass] = useState("");
    const [confirmPass, setConfirmPass] = useState("")
    const [show, setShow] = useState(false);
    const [showNew, setShowNew] = useState(false);
    const [showCon, setShowCon] = useState(false);

    // const mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    // const passWord = passord.length >= 4 && passord.length <= 15;
    const handleSubmit = async (e) => {
        e.preventDefault();

        // const isValid = email.trim() !=="" && passord.trim() !=="";
        // if(isValid){
        //   // toast.success("login successfully")
        //   if(!email.match(mailFormat)){
        //     toast.error("Invalid Email")
        //   } else {
        //     const data = {
        //         email: email,
        //         password: passord,
        //       };
        //       const res = await AuthServices.login(data);
        //       console.log("resCat", res);
        //       if (res && res.status) {
        //         reactLocalStorage.set("Auth", res?.data?.token);
        //         toast.success("Login Successfully");
        //         navigate("/");
        //         setEmail("");
        //         setPassword("");
        //       } else {
        //         toast.error(res.message);
        //       } 
        //   }
        //   // console.log(first)
        // } else {
        //   toast.error("Please fill all the Inputs..") 
        // }

        if (newPass !== confirmPass) {
            toast?.error("New password and confirm password do not match.");
            return;
        }

        const data = {
            oldPass: oldPass,
            newPass: newPass,
        };
        const res = await HttpClientXml?.requestData("edit-pass", data, "POST");
        // console.log("resChange", res);
        if (res && res?.success) {
            toast?.success(res?.message);
            navigate("/login");
            setoldPass("");
            setnewPass("");
        } else {
            toast.error(res?.message);
            // toast.success("Password change succesfully");
            // navigate("/login");
            setoldPass("");
            setnewPass("");
        }
    };

    const togglePasswordVisibility = () => {
        setShow(!show);
    };

    const toggleNewPasswordVisibility = () => {
        setShowNew(!showNew);
    };

    const toggleConPasswordVisibility = () => {
        setShowCon(!showCon);
    };
    return (
        <>
            <section
                className="LoginPage"
                style={{ backgroundImage: `url(${loginbg})` }}
            >
                <div className="LoginBgOverlay" />
                <div className="LoginForm">
                    <div className="LoginTop">
                        <h5 className="LoginHead">Change Password</h5>
                    </div>
                    <div className="LoginBtm">
                        <form action="">
                            {/* <div className="form-group">
                <input
                  name="email"
                  id="exampleEmail"
                  placeholder="Email here..."
                  type="email"
                  onChange={(e) => setEmail(e.target.value)}
                  className="form-control"
                />
              </div> */}
                            <div className="form-group pass_input">
                                <input
                                    onChange={(e) => setoldPass(e.target.value)}
                                    value={oldPass}
                                    name="oldPass"
                                    id="examplePassword"
                                    placeholder="Old Password here..."
                                    type={show ? "text" : "password"}
                                    className="form-control"
                                />
                                <div className="eye_icon"
                                    onClick={togglePasswordVisibility}
                                >
                                    {/* <i className="fa-solid fa-eye"></i> */}
                                    {show ? < FaEye /> : < FaEyeSlash />}
                                </div>
                            </div>

                            <div className="form-group pass_input">
                                <input
                                    onChange={(e) => setnewPass(e.target.value)}
                                    value={newPass}
                                    name="newPass"
                                    id="examplePassword"
                                    placeholder="New Password here..."
                                    type={showNew ? "text" : "password"}
                                    className="form-control"
                                />
                                <div className="eye_icon"
                                    onClick={toggleNewPasswordVisibility}
                                >
                                    {/* <i className="fa-solid fa-eye"></i> */}
                                    {showNew ? < FaEye /> : < FaEyeSlash />}
                                </div>
                            </div>

                            <div className="form-group pass_input">
                                <input
                                    onChange={(e) => setConfirmPass(e.target.value)}
                                    value={confirmPass}
                                    name="confirmPass"
                                    id="examplePassword"
                                    placeholder="Confirm Password here..."
                                    type={showCon ? "text" : "password"}
                                    className="form-control"
                                />
                                <div className="eye_icon"
                                    onClick={toggleConPasswordVisibility}
                                >
                                    {/* <i className="fa-solid fa-eye"></i> */}
                                    {showCon ? < FaEye /> : < FaEyeSlash />}
                                </div>
                            </div>
                            {/* <div className="form-group">
                <input type="Checkbox" />
                <span className="LoginRem">Remember Me</span>
              </div> */}
                        </form>
                        <button
                            className="LoginBtn"
                            type="submit"
                            onClick={(e) => handleSubmit(e)}
                        >
                            Change
                        </button>
                    </div>
                </div>
            </section>
        </>
    );
}
