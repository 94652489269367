import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import HttpClientXml from '../../utils/HttpClientXml';
import toast from 'react-hot-toast';
import EditDeleteIcon from '../../CustomComponents/EditDeleteIcon';
import { DeleteConfirmModal } from '../../CustomComponents/DeleteConfirmModal';
import { ImCross } from 'react-icons/im';

const customStyles = {
    rows: {
        style: {
            minHeight: '48px', // override the row height
        },
    },
    headCells: {
        style: {
            paddingLeft: '8px', // override the cell padding for head cells
            paddingRight: '8px',
            backgroundColor: '#cee0eb', // set the background color for head cells
        },
    },
    cells: {
        style: {
            paddingLeft: '8px', // override the cell padding for data cells
            paddingRight: '8px',
        },
    },
};
const headLineStyle = {
    textAlign: "center",
    fontSize: "30px",
    color: "#868e96",
    margin: "35px",
    fontWeight: "bold"

}

const Industry = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [AllData, setAllData] = useState([]);
    const [isEdit, setIsEdit] = useState(false);
    const [editid, seteditid] = useState();
    const [IndustryName,setIndustryName]=useState("");

    const initValue = {
        name: ""
    }
    const [formvalue, setformvalue] = useState(initValue);
    const columns = [
        {
            name: 'SL',
            selector: row => row.sl,
            width: "10%"

        },

        {
            name: 'Type Name',
            selector: row => row.name,
            width: "35%"
        },

        {
            name: 'Action',
            selector: row => row.action,
        }
    ];

    //for getting all salary type
    const getsalarytypedata = async () => {
        setIsLoading(true);
        const res = await HttpClientXml.requestData("get-industry-type", {}, "GET");
        console.log("industryup", res)
        let apiData = []
        if (res && res?.status) {
            setIsLoading(false);
            apiData = res?.data?.map((item, i) => ({
                sl: i + 1,
                name: item?.name,
                action: <EditDeleteIcon
                    onClickEdit={(e) => handleEdit(item)}
                    onClickDelete={(e) => handleDelete(item?._id)}
                />
            }));
        } else {
            setIsLoading(false);
        }

        setAllData(apiData);
    }


    //for search industry
    const searchIndustry = async () => {
        let data = {}
        data = {
            searchName: IndustryName
        }
        const res = await HttpClientXml.requestData("search-industry-type", data, "POST");
        let apiData = []
        if (res && res?.status) {
            setIsLoading(false);
            apiData = res?.data?.map((item, i) => ({
                sl: i + 1,
                name: item?.name,
                action: <EditDeleteIcon
                    onClickEdit={(e) => handleEdit(item)}
                    onClickDelete={(e) => handleDelete(item?._id)}
                />
            }));
        }
        setAllData(apiData);
    };


    useEffect(() => {
        let debounce = setTimeout(() => {
            searchIndustry();
        }, 400)

        return (() => clearTimeout(debounce))
    }, [IndustryName])

    //for submit functionality of salary type
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!formvalue?.name) {
            toast.error('Input blank')
            return;
        }

        const data = {
            name: formvalue?.name
        }
        setIsLoading(true);
        const res = await HttpClientXml.requestData("add-industry-type", data, "POST");
        // console.log("resCat", res)
        if (res && res?.status) {
            toast.success("Added Successfully");
            setformvalue(initValue);
            setIsLoading(false);
            getsalarytypedata();


        } else {
            toast.error(res?.message || "Something Wrong");
            setIsLoading(false);

        }
    }


    //for delete functionality of salary type
    const handleDelete = (id) => {
        const del = async () => {
            setIsLoading(true);
            const res = await HttpClientXml.requestData("delete-industry-type/" + id, {}, "PUT")
            if (res && res?.status) {
                setIsLoading(false);
                getsalarytypedata();

                toast.success("Deleted Successfully");
            } else {
                setIsLoading(false);
                toast.error(res?.message || "Something Wrong");
            }
        }

        DeleteConfirmModal(del);
    }


    //Edit
    const handleEdit = async (item) => {
        setIsEdit(true);
        seteditid(item?._id);
        setformvalue({
            name: item?.name,
        })
    }

    //for cancel edit
    const cancelEdit = (e) => {
        setIsEdit(false)
        setformvalue(initValue);
    }

    // edit submit
    const handleEditSubmit = async (e) => {
        e.preventDefault();

        // if (!selectedValue || !userTypeValue) {
        //     toast.error('Input blank')
        //     return;
        // }

        const data = {
            name: formvalue?.name,
        }
        setIsLoading(true);
        const res = await HttpClientXml.requestData("edit-industry-type/" + editid, data, "PUT");
        // console.log("resCat", res)
        if (res && res?.status) {
            toast.success("Updated Successfully");
            setIsEdit(false)
            setformvalue(initValue)
            setIsLoading(false);
            getsalarytypedata();


        } else {
            toast.error(res?.message || "Something Wrong");
            setIsLoading(false);

        }
    }


    //change state values
    const handleChange = (e) => {
        const { name, value } = e.target
        setformvalue(prev => ({ ...prev, [name]: value }));
    };

    useEffect(() => {
        getsalarytypedata();
    }, [])

    return (
        <div className="d-flex justify-content-end">


            <div className="form-header">
                <section className="piechartsBox_area">

                    <div className="Search_box">
                        <form className="form-inline">
                            <input
                                className="form-control"
                                type="search"
                                placeholder="Search"
                                aria-label="Search"
                                value={IndustryName}
                                onChange={(e) => setIndustryName(e.target.value)}
                            />
                            <button className="Search_icon" type="submit">
                                <i className="fa-solid fa-magnifying-glass"></i>
                            </button>
                        </form>
                    </div>

                    <div
                        style={headLineStyle}
                        className="page-headline"
                    >
                        Industry type
                    </div>

                    <form>
                        <div className="row">
                            <div className="col-6 ">
                                <label htmlFor="formGroupExampleInput">Industry:
                                    <span className="text-danger">&nbsp;*</span></label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Industry"
                                    name="name"
                                    onChange={handleChange}
                                    value={formvalue?.name}
                                />
                            </div>

                        </div>


                        {/* Button */}
                        <div class="col-12 d-flex justify-content-end ">
                            {
                                !isEdit ? <button
                                    type="submit"
                                    onClick={(e) => handleSubmit(e)}
                                    class="btn btn-primaryb mt-3"
                                    style={{ background: "linear-gradient(195deg, rgb(66, 66, 74), rgb(25, 25, 25))", color: "#fff" }}
                                >
                                    Add Industry type
                                </button> :
                                    <div className=' d-flex '>
                                        <button
                                            type="submit"
                                            onClick={(e) => handleEditSubmit(e)}
                                            class="btn btn-primaryb mt-3 btn-grad"
                                        >
                                            Update Industry type
                                        </button>
                                        <ImCross
                                            onClick={(e) => cancelEdit(e)}
                                            style={{ fontSize: "20px", cursor: "pointer" }} className=" mx-3 mt-4 text-danger " />
                                    </div>

                            }
                        </div>
                    </form>

                    <form>
                        <div
                            style={headLineStyle}
                            className="page-headline"
                        >
                            View and manage Industry type
                        </div>
                        <div>

                            <DataTable
                                columns={columns}
                                data={AllData}
                                pagination
                                striped
                                className=" rounded mt-1"
                                customStyles={customStyles}
                            />
                        </div>
                    </form>

                </section>
            </div>
        </div>
    )
}

export default Industry