import React, { useEffect, useState } from "react";
import HomeCard from "./HomeCard";
import "./Home.css";
import PieChartsBox from "./PieChartsBox";
import HttpClientXml from "../../utils/HttpClientXml";
import { useNavigate } from "react-router-dom";

const Index = () => {
  const navigate = useNavigate();
  const [empStrCount, setEmpStrCount] = useState([]);
  const [catagory, setCatagory] = useState([]);
  const [empStrData, setempStrData] = useState([]);
  const [SelectedData, setSelectedData] = useState([]);

  //for viewing count employee strength in pie chart 
  const viewEmpCount = async () => {
    let res = await HttpClientXml?.requestData("map-employee-strength", {}, "GET");
    if (res && res?.status) {
      setempStrData(res?.data)
      let e = res?.data?.map((item) => item?.totalcount);
      let arrayE = Object?.values(e);
      setEmpStrCount(arrayE);
      let c = res?.data?.map((item) => item?._id);
      let array = Object?.values(c);
      setCatagory(array);

      setSelectedData(res?.data)

    } else {

    }
  };

  const handlemodalClick = (answer) => {
    navigate(`/view-company/${answer}`,{state: SelectedData})
  };

  useEffect(() => {
    viewEmpCount();
  }, [])

  return (
    <>
      <div className="main_wrap">
        <div className="container-fluid">
          <HomeCard />

          <div className='row mt-2'>
            {/* <div className='col-md-7 col-12'> */}
            <div className="col-6  mt-2 mx-3">
              {/* <ChartBox /> */}
              <table className="table border border-black "
              >
                <thead>
                  <tr>

                    <th>Index</th>
                    <th>Employee Strength</th>
                    <th>Count</th>
                    <th></th>

                  </tr>
                </thead>
                <tbody>
                  {
                    empStrData?.map((item) => {
                      return (
                        <>
                          {item?._id === "A" && <tr className="table-primary">

                            <td>A</td>
                            <td>Under 50</td>
                            <td>{item?.totalcount}</td>
                            <td><button className='btn btn-success' onClick={() => { handlemodalClick("A") }}>View</button></td>

                          </tr>}

                          {item?._id === "B" && <tr className="table-primary">

                            <td>B</td>
                            <td>50-200</td>
                            <td>{item?.totalcount}</td>
                            <td><button className='btn btn-success' onClick={() => { handlemodalClick("B") }}>View</button></td>

                          </tr>}

                          {item?._id === "C" && <tr className="table-primary">

                            <td>C</td>
                            <td>201-500</td>
                            <td>{item?.totalcount}</td>
                            <td><button className='btn btn-success' onClick={() => { handlemodalClick("C") }}>View</button></td>

                          </tr>}

                          {item?._id === "D" && <tr className="table-primary">

                            <td>D</td>
                            <td>501-1000</td>
                            <td>{item?.totalcount}</td>
                            <td><button className='btn btn-success' onClick={() => { handlemodalClick("D") }}>View</button></td>

                          </tr>}

                          {item?._id === "E" && <tr className="table-primary">

                            <td>E</td>
                            <td>Above 1000</td>
                            <td>{item?.totalcount}</td>
                            <td><button className='btn btn-success' onClick={() => { handlemodalClick("E") }}>View</button></td>

                          </tr>}
                        </>
                      )
                    })
                  }
                  {/* {catagory?.A && <tr className="table-primary">

                    <td>A</td>
                    <td>{catagory?.A}</td>

                  </tr>}
                  {catagory?.B && <tr className="table-secondary">

                    <td>B</td>
                    <td>{catagory?.B}</td>

                  </tr>}
                  {catagory?.C && <tr className="table-success">

                    <td>C</td>
                    <td>{catagory?.C}</td>

                  </tr>}
                  {catagory?.D && <tr className="table-info">

                    <td>D</td>
                    <td>{catagory?.D}</td>

                  </tr>
                  } */}

                </tbody>
              </table>
            </div>
            <div className='col-md-5 col-12'>
              <PieChartsBox empStrCount={empStrCount} catagory={catagory} />
            </div>
          </div>

        </div>
      </div>
    </>
  );
};

export default Index;
