import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import HttpClientXml from "../../utils/HttpClientXml";


const HomeCard = () => {
const navigate=useNavigate();
const [countCompany,setCountCompany]=useState(null);


 //for viewing count approved registered company 
 const viewApprovedCompanyCount = async () => {
  let res = await HttpClientXml?.requestData("view-approved-company", {}, "GET");
  if (res && res?.status) {
    setCountCompany(res?.data?.length);
  } else {
   
  }
};

useEffect(()=>{
  viewApprovedCompanyCount();
},[])

  // const card = [
  //   {
  //     id: 1,
  //     color: "rgb(236, 64, 122)",
  //     name: "Budding Talent Count",
  //     link: "/buddingview",
  //     userType: "BuddingTalent",
  //   },
  //   {
  //     id: 2,
  //     color: "rgb(73, 163, 241)",
  //     name: "Influencer",
  //     link: "/influencerview",
  //     userType: "Influencer",
  //   },
  //   {
  //     id: 3,
  //     color: "rgb(102, 187, 106)",
  //     name: "Scout",
  //     link: "/scoutview",
  //     userType: "Scout",
  //   },
  // ];

  return (
    <>
      <div className="HomeTop">
        <div className="row">
       
              <div className="col-md-4 col-12">
                <div className="HomeCard">
                  <h5 className="HomeCardHead">Registered Company</h5>
                  <p className="HomeCardPara">{countCompany}</p>
                  <hr />

                  <div style={{width: "100%", display: "flex", alignItems: "center", justifyContent: "end"}}>
                    <button
                      className="HomeCardViewbtn" onClick={()=>navigate('/approved-company')}
                    >
                      View
                    </button>
                  </div>
                  
                  <div
                    className="cardIconDiv"
                    style={{ backgroundColor: "rgb(236, 64, 122)"}}
                  >
                    <i class="fa-regular fa-user"></i>
                  </div>
                </div>
              </div>

              <div className="col-md-4 col-12">
                <div className="HomeCard">
                  <h5 className="HomeCardHead">Subscription Plans</h5>
                  <p className="HomeCardPara">0</p>
                  <hr />

                  <div style={{width: "100%", display: "flex", alignItems: "center", justifyContent: "end"}}>
                    <button
                      className="HomeCardViewbtn"
                    >
                      View
                    </button>
                  </div>
                  
                  <div
                    className="cardIconDiv"
                    style={{ backgroundColor: "rgb(73, 163, 241)" }}
                  >
                    <i class="fa-regular fa-user"></i>
                  </div>
                </div>
              </div>

        </div>
      </div>
    </>
  );
};

export default HomeCard;
