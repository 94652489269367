import React, { useEffect, useState } from 'react'
// import { ImCross } from 'react-icons/im'
// import CustomLoader from '../../CustomComponents/loader/CustomLoader'
import toast from 'react-hot-toast'
import DataTable from 'react-data-table-component'
// import PieChartsBox from '../../View/Home/PieChartsBox'

import EditDeleteIcon from '../../CustomComponents/EditDeleteIcon'
import { DeleteConfirmModal } from '../../CustomComponents/DeleteConfirmModal'
import HttpClientXml from '../../utils/HttpClientXml'

const headLineStyle = {
    textAlign: "center",
    fontSize: "30px",
    color: "#868e96",
    margin: "35px",
    fontWeight: "bold"

}
const customStyles = {

    headCells: {
        style: {
            // paddingLeft: '8px', // override the cell padding for head cells
            // paddingRight: '8px',
            backgroundColor: '#cee0eb', // set the background color for head cells
        },
    },

};

const StaticData = () => {
    const [AllData, setAllData] = useState([]);
    const [isEdit, setIsEdit] = useState(false);
    // const [checked, setChecked] = useState('')
    const [EditID, setEditID] = useState('')
    const [question, setQuestion] = useState("")
    const [questionAnswerData, setquestionAnswerData] = useState(['', '']);
    const [questionName, setquestionName] = useState("");
    // console.log("questionAnswerData", questionAnswerData)
    // console.log("AllData", AllData)
    // console.log("EditID", EditID)

    useEffect(() => {
        getCategoryData();
    }, [])

    // >>>Data Table Column<<<
    const columns = [
        {
            name: 'SL',
            selector: row => row.sl,
            width: "3.4rem"
        },
        {
            name: 'Question',
            selector: row => row.surveyQuestion,
            width: "35.7rem"
        },

        {
            name: 'Participants',
            selector: row => row.totalParticipants,
            width: "7.4rem"
        },
        {
            name: 'Ans-1',
            selector: row => row.A,
            width: "7.4rem"
        },
        {
            name: 'Ans-2',
            selector: row => row.B,
            width: "7.4rem"
        },
        {
            name: 'Ans-3',
            selector: row => row.C,
            width: "7.4rem"
        },
        {
            name: 'Ans-4',
            selector: row => row.D,
            width: "7.4rem"
        },
        {
            name: 'Action',
            selector: row => row.action,
            width: "7.4rem"
        },

    ];
    // fetch Category DAta
    const getCategoryData = async () => {
        // alert("fjdfds")
        // setIsLoading(true);
        const res = await HttpClientXml?.requestData('get-login-survey', {}, "GET");
        console.log("resGetCat", res)
        let apiData = []
        if (res && res?.status) {
            // setIsLoading(false);
            apiData = res?.data?.[0]?.data?.map((item, i) => ({
                id: i + 1,
                sl: i + 1,
                surveyQuestion: item?.surveyQuestion,
                A: item?.A,
                B: item?.B,
                C: item?.C ? item?.C : "N/A",
                D: item?.D ? item?.D : "N/A",
                totalParticipants: item?.totalParticipants,
                action: <EditDeleteIcon
                    onClickEdit={(e) => handleEdit(item)}
                    onClickDelete={(e) => handleDelete(item?._id)}
                />
            }));
        } else {
            // setIsLoading(false);
        }
        setAllData(apiData);
    }

    //for search question
    const searchQuestion = async () => {
        let data = {}
        data = {
            searchName: questionName
        }
        const res = await HttpClientXml?.requestData("search-login-survey", data, "POST");
        let apiData = []
        if (res && res?.status) {
            // setIsLoading(false);
            apiData = res?.data?.[0]?.data?.map((item, i) => ({
                id: i + 1,
                sl: i + 1,
                surveyQuestion: item?.surveyQuestion,
                A: item?.A,
                B: item?.B,
                C: item?.C ? item?.C : "N/A",
                D: item?.D ? item?.D : "N/A",
                totalParticipants: item?.totalParticipants,
                action: <EditDeleteIcon
                    onClickEdit={(e) => handleEdit(item)}
                    onClickDelete={(e) => handleDelete(item?._id)}
                />
            }));
        }
        setAllData(apiData);
    };


    useEffect(() => {
        let debounce = setTimeout(() => {
            searchQuestion();
        }, 400)

        return (() => clearTimeout(debounce))
    }, [questionName])



    // >>>Handle Change All Input Value<<<
    const handleChange = (e, index) => {
        const { name, value } = e.target;
        setquestionAnswerData(state => {
            state[index] = value
            return JSON.parse(JSON.stringify(state))
        })
    }

    // >>>Handle Edit Data<<<
    const handleEdit = (item) => {
        console.log(item, "itemEdit")
        setIsEdit(true);
        setQuestion(item?.surveyQuestion)
        setEditID(item?._id);
        setquestionAnswerData([item?.A, item?.B, item?.C, item?.D]);
    }

    // edit submit
    const handleEditSubmit = async (e, id) => {
        e.preventDefault();
        // const newData = {
        //     surveyQuestion: question,

        // };

        let objectKeys = ["A", "B", "C", "D"]

        let data = questionAnswerData?.reduce((prev, current, currentIndex) => {
            let data = {
                ...prev,
                [objectKeys[currentIndex]]: current
            }
            return data
        }, {})

        // console.log("da46ta", question, data.A) 
        const newData = {
            surveyQuestion: question,
            ...data
        };

        if (!question) {
            return toast.error("Please Fill Some Data")
        }
        else {

            const res = await HttpClientXml.requestData("edit-login-survey/" + id, newData, "PUT")
            if (res && res?.status) {
                setIsEdit(false)
                toast.success('Successfully Updated')
                getCategoryData()
                setQuestion('')
                setquestionAnswerData(['', ''])
            }
            else {
                setIsEdit(false)
                toast.error("Update Failed")

            }
        }
    }

    //handle delete question
    const handleDelete = async (id) => {
        const del = async () => {

            const res = await HttpClientXml.requestData("delete-login-survey/" + id, {}, "PUT")
            if (res && res?.status) {
                getCategoryData()
                toast.success("Question Deleted Successfully");
            } else {
                toast.error(res?.message || "Something Wrong");
            }
        }
        DeleteConfirmModal(del);
    }

    // >>>Handle Submit Data<<<
    const handleSubmit = async (e) => {
        e.preventDefault()
        let objectKeys = ["A", "B", "C", "D"]

        let data = questionAnswerData?.reduce((prev, current, currentIndex) => {
            let data = {
                ...prev,
                [objectKeys[currentIndex]]: current
            }
            return data
        }, {})

        // console.log("da46ta", question, data.A) 
        const newData = {
            surveyQuestion: question,
            ...data
        };
        // }

        console.log("first564", newData)
        // return
        const res = await HttpClientXml.requestData("add-survey-question", newData, "POST");
        if (res && res?.status) {
            console.log("myResponse1234", res)
            toast.success("Added successfully")
            setQuestion("")
            setquestionAnswerData(["", ""])
            getCategoryData()
        }
        else {
            toast.success(res?.msg)
        }
    }
    return (
        <div>
            <div className="d-flex justify-content-end">
                {/* <CustomLoader loading={isLoading} /> */}
                <div className="form-header">
                    <section className="piechartsBox_area">

                        <div className="Search_box">
                            <form className="form-inline">
                                <input
                                    className="form-control"
                                    type="search"
                                    placeholder="Search"
                                    aria-label="Search"
                                    value={questionName}
                                    onChange={(e) => setquestionName(e.target.value)}
                                />
                                <button className="Search_icon" type="submit">
                                    <i className="fa-solid fa-magnifying-glass"></i>
                                </button>
                            </form>
                        </div>

                        <div
                            style={headLineStyle}
                            className="page-headline"
                        >
                            {isEdit ? "Edit Question & Answer" : "Add Question & Answer"}
                        </div>



                        <form class="row g-3 m-2">
                            <div class="col-md-12">
                                {isEdit ? <label for="inputEmail4" className="form-label edit-tag">
                                    Edit Question
                                </label> : <label for="inputEmail4" className="form-label ">
                                    Question
                                </label>}

                                <span style={{ color: "red" }}>*</span>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Write your question..."
                                    onChange={(e) => setQuestion(e.target.value)}
                                    value={question}
                                    name="question"
                                />
                            </div>

                            {
                                // !isEdit && 
                                questionAnswerData?.map((item, index) => {
                                    return (
                                        <div class="col-md-6" key={index}>
                                            <label for="inputEmail4" className={`form-label ${isEdit ? 'edit-tag' : ''}`}>
                                                Answer-{index + 1}
                                            </label>

                                            <span style={{ color: "red" }}>*</span>
                                            <div className=' d-flex '>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder='write your answer...'
                                                    onChange={(e) => handleChange(e, index)}
                                                    value={item}
                                                    name="answer1"
                                                />
                                                {
                                                    questionAnswerData.length > 2 &&
                                                        (index === 2 || index === 3) ?
                                                        <button
                                                            type='button'
                                                            className="btn btn-danger mx-2 "
                                                            style={{
                                                                width: "35px",
                                                                fontSize: "18px",
                                                                padding: "5px",
                                                                height: "35px",
                                                                alignItems: "center", // Align items to center
                                                                justifyContent: "center", // Justify content to center
                                                                display: "flex", // Use flexbox
                                                                border: "none", // Remove border
                                                                borderRadius: "50%", // Make button round
                                                                cursor: "pointer", // Change cursor to pointer

                                                            }}
                                                            onClick={(e) => {
                                                                e.preventDefault()
                                                                setquestionAnswerData(state => {
                                                                    if (state.length >= 2) state.splice(index, 1)

                                                                    return JSON.parse(JSON?.stringify(state))
                                                                })
                                                            }}>X</button>
                                                        :
                                                        null
                                                }
                                            </div>

                                        </div>
                                    )
                                })
                            }


                            {/* <div class="col-md-6">
                                {isEdit ? <label for="inputEmail4" className="form-label edit-tag">
                                    Answer-2
                                </label> : <label for="inputEmail4" className="form-label">
                                    Answer-2
                                </label>}

                                <span style={{ color: "red" }}>*</span>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder='write your answer...'
                                    onChange={handleChange}
                                    value={questionAnswerData?.answer2}
                                    name="answer2"
                                />
                            </div> */}
                        </form>

                        {/* {questionAnswerData?.map((item, index) => {
                            return (
                                //                
                                <>
                                    {checked &&
                                        <>
                                            <div style={{ marginTop: "2em", marginRight: "10px" }}>
                                                {index == 0 ?
                                                    <span style={{ width: "20px", marginLeft: "30px" }}></span>
                                                    :
                                                    <button className="btn btn-danger" style={{ width: "30px", fontSize: "20px", padding: "5px" }} onClick={() => {
                                                        let arr = questionAnswerData.filter((ele, ind) => index !== ind);
                                                        setquestionAnswerData([...arr])
                                                    }}>X</button>
                                                }
                                            </div>
                                            <form class="row g-3 m-2">
                                                <div class="col-md-6">
                                                    {isEdit ? <label for="inputEmail4" className="form-label edit-tag">
                                                        Answer-2
                                                    </label> : <label for="inputEmail4" className="form-label">
                                                        Answer-2
                                                    </label>}

                                                    <span style={{ color: "red" }}>*</span>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder='write your answer...'
                                                        onChange={handleChange}
                                                        value={item?.answer2}
                                                        name="answer2"
                                                    />
                                                </div>
                                            </form>
                                        </>
                                    }
                                </>
                            )
                        })} */}
                        <div class="col-12 d-flex justify-content-between  ">

                            {
                                // !isEdit && 
                                <div>
                                    <button onClick={() => {
                                        setquestionAnswerData(state => {
                                            if (state.length <= 3) {
                                                state.push('')
                                            }
                                            return JSON.parse(JSON.stringify(state))
                                        })
                                    }} class="btn btn-primaryb mt-3"
                                        style={{ background: "linear-gradient(195deg, rgb(150, 86, 44), rgb(25, 25, 25))", color: "#fff" }}>Add More Fields</button>
                                </div>}
                            <div>
                                {isEdit ? <>
                                    <div className="d-flex ">
                                        <button
                                            type="submit"
                                            onClick={(e) => handleEditSubmit(e, EditID)}
                                            className="btn btn-primaryb mt-3 btn-grad"
                                            style={{ background: "linear-gradient(195deg, rgb(66, 66, 74), rgb(25, 25, 25))", color: "#fff" }}
                                        //style={{ background: "linear-gradient(195deg, rgb(66, 66, 74), rgb(25, 25, 25))", color: "#fff" }}
                                        >
                                            Update
                                        </button>
                                        {/* <ImCross
                                        // onClick={(e) => handleisCancelSubmit(e)} 
                                        style={{ fontSize: "25px", cursor: "pointer" }} className=" mx-3 mt-4 text-danger " /> */}

                                    </div>


                                </>
                                    :
                                    <>

                                        <button
                                            type="submit"
                                            onClick={handleSubmit}
                                            class="btn btn-primaryb mt-3"
                                            style={{ background: "linear-gradient(195deg, rgb(66, 66, 74), rgb(25, 25, 25))", color: "#fff" }}
                                        >
                                            Add
                                        </button>

                                        {/* <button
                                            type="submit"
                                            onClick={(e) => handleEdit(e)}
                                            class="btn btn-primaryb mt-3 mx-2"
                                            style={{ background: "linear-gradient(195deg, rgb(66, 66, 74), rgb(25, 25, 25))", color: "#fff" }}
                                        >
                                            Edit
                                        </button> */}
                                    </>
                                }
                            </div>

                        </div>
                    </section>
                    <form>
                        <div
                            style={headLineStyle}
                            className="page-headline"
                        >
                            View Question & Answer
                        </div>
                        <div>
                            <DataTable
                                columns={columns}
                                data={AllData}
                                pagination
                                striped
                                className=" rounded "
                                customStyles={customStyles}
                            />
                        </div>
                    </form>

                    {/* <div className='mt-4 ' > */}

                    {/* <div className="page-headline">Question Analysis Chart</div> */}
                    {/* <PieChartsBox /> */}
                    {/* </div> */}
                </div>


            </div>

        </div>
    )
}

export default StaticData