import React from 'react'
import './UserEmailModal.css'


const UserEmailModal = ({ closemodal, answerData }) => {
    // console.log("answerData",answerData)
    const handleStop = (e) => {
        e.stopPropagation()
    }
    return (
        <section className="createeventdetails" onClick={() => closemodal(false)}>
            <div className="detailspagecontainer" onClick={handleStop}>
                <div className="createdivfrmmain">
                    <form>
                        <div className=''>
                            <div className="useemiailmnodaloadding" >

                                <th>
                                    <div className='d-flex'>
                                        <tr>
                                            <p className="rsntxrpnewtxt">User Email :</p>
                                        </tr>
                                        <div className='cross_reg' onClick={() => closemodal(false)}>
                                            <i className="fa-solid fa-xmark"></i>
                                        </div>
                                    </div>
                                    <>
                                        {answerData?.length > 0 ?
                                            <p className='emailtxtpppptt'>{answerData?.map((item, i) => {
                                                return (
                                                    <>
                                                        <tr>({i + 1}) {item?.userEmail}</tr>
                                                    </>
                                                )
                                            }
                                            )
                                            }
                                            </p>
                                            : "None"
                                        }
                                    </>
                                </th>





                            </div>

                        </div>

                    </form>
                </div>
            </div>
        </section>
    )
}

export default UserEmailModal