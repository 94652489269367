import { BrowserRouter, Routes, Route } from "react-router-dom";
import React from "react";
import "./App.css";
import "../src/Component/Modal/Modal.css";
import Home from "../src/View/Home/Index";
import Login from "./Athentication/Login";
import MainLayout from "./Layout/MainLayout";
import PrivateRouter from "./privateRouter/PrivateRouter"; 
import NotFound from "./NotFound/NotFound";
import RegisterCompanyView from "./View/RegisterCompany/RegisterCompanyView";
import ApprovedCompany from "./View/RegisterCompany/ApprovedCompany";
import ChangePassword from "./Athentication/ChangePassword";
import Industry from "./View/Industry/Industry";
import StaticData from "./View/LoginSurvey/StaticData";
import Analys from "./View/LoginSurvey/Analys";
import EmpStrCompany from "./View/Home/EmpStrCompany";


const App = () => {
  
  return (
    <> 
      <BrowserRouter>
        <Routes>

          <Route element={<PrivateRouter />}>
            <Route element={<MainLayout />}>
              <Route path="/" element={<Home />} /> 
              <Route path="/view-company/:ans" element={<EmpStrCompany />} /> 
              <Route path="/register-company" element={<RegisterCompanyView />} /> 
              <Route path="/approved-company" element={<ApprovedCompany />} /> 
              <Route path="/industry" element={<Industry />} /> 
              <Route path="/login-survey-question" element={<StaticData />} />
              <Route path="/analys-data" element={<Analys />} />
            </Route>
          </Route>
          <Route path="/change-password" element={<ChangePassword />} /> 
          <Route path="/login" element={<Login />} />
          <Route path="*" element={<NotFound />} />

        </Routes>
      </BrowserRouter>
    </>
  );
};

export default App;
