import {
    createContext,
    useContext,
    useEffect,
    useState,
  } from "react";

import { ApiHelperFunction } from "../Services/AuthServices";
  
  const AuthContext = createContext(null);
  
  export const useAuthCtx = () => useContext(AuthContext);
  
  export const ContextProvider = ({ children }) => {
    const [logIn, setLogIn] = useState(
      () => localStorage.getItem("Auth") || false
    );
    const [userData, setUserData] = useState({});

  
    // get logged in super admin details
    const getUserDetails = async (data = {}) => {
      let urlPath = "get-super-Admin";
      let res = await ApiHelperFunction({ urlPath, method: "GET", data });
      if (res?.status) {
        setUserData(res?.data?.data);
      } else {
        // toast.error(res?.data?.message || "Error Fetching User Details");
      }
    };
  
  
    useEffect(() => {
      if (logIn) {
        getUserDetails();
      }
    }, [logIn]);
  
    return (
      <AuthContext.Provider
        value={{
          logIn,
          setLogIn,
          userData,
          getUserDetails,
         
        }}
      >
        {children}
      </AuthContext.Provider>
    );
  };
  